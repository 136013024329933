<template>
    <el-dialog :visible="visible" :title="dialogTitle" @close="handleCancel" :close-on-click-modal="false" width="1000px">
        <el-table :data="tableData" v-loading="loading" border height="400">
            <el-table-column prop="StoreName" label="店铺名称" width="150" align="center">
                <template slot-scope="{ row }">
                    <template v-if="row.ID > 0">
                        {{ row.StoreName }}
                    </template>
                    <template v-else>
                        <el-input v-model="row.StoreName" placeholder="请输入店铺名称"></el-input>
                    </template>
                </template>
            </el-table-column>
            <el-table-column prop="WebsiteTypeText" label="所属平台" width="100" align="center">
                <template slot-scope="{ row }">
                    {{ row.WebsiteTypeText }}
                </template>
            </el-table-column>
            <el-table-column prop="StoreUrl" label="店铺网址" align="center">
                <template slot-scope="{ row }">
                    <template v-if="row.ID > 0">
                        {{ row.StoreUrl }}
                    </template>
                    <template v-else>
                        <el-input v-model="row.StoreUrl" placeholder="请输入店铺网址"></el-input>
                    </template>
                </template>
            </el-table-column>
            <el-table-column prop="CreatedAt" label="绑定时间" width="170" align="center">
                <template slot-scope="{ row }">
                    {{ row.CreatedAt ? row.CreatedAt : '-' }}
                </template>
            </el-table-column>
            <el-table-column prop="LastSynTime" label="最后同步时间" width="170" align="center">
                <template slot-scope="{ row }">
                    <template v-if="!row.LastSynTime">-</template>
                    <el-tooltip v-else class="tooltip-item" effect="dark" :content="row.LastSynResult" placement="top">
                        <el-link :type="row.LastSynResult.indexOf('成功') > -1 ? 'success' : 'danger'">{{ row.LastSynTime
                            }}</el-link>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column prop="" header-align="center" align="center" label="操作" width="160">
                <template slot="header">
                    <el-link icon="el-icon-refresh" style="color:#409EFF;" @click="getData">刷新</el-link>
                </template>
                <template slot-scope="{ row,$index }">
                    <el-popconfirm v-if="row.ID > 0" confirm-button-text='确认' cancel-button-text='不用了'
                        icon="el-icon-info" icon-color="red" title="确定删除吗？" @confirm="handleDelete(row, $index)">
                        <el-button type="danger" slot="reference" :disabled="row.SynStatus">删除</el-button>
                    </el-popconfirm>
                    <el-button type="primary" style="margin-left: 10px;" :disabled="row.SynStatus"
                        @click="handleImportByStoreUrls(row)" v-if="row.ID > 0">同步{{ row.SynStatus ? '中' : ''
                        }}</el-button>
                    <el-button type="primary" @click="handleAdd(row, $index)" v-if="row.ID <= 0">新增</el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-dialog>
</template>
<script>
import { submit } from "@/api/base";
export default {
    name: 'SynPlatformSetting',
    data() {
        return {
            tableData: [],
            visible: false,
            loading: false,
            dialogTitle: '同步设置',
        };
    },
    methods: {
        async open() {
            this.visible = true;
            await this.getData();
        },
        addEmptyRow() {
            var count = this.tableData.length;
            if (count <= 0 || (count > 0 && this.tableData[count - 1].ID > 0)) {
                this.tableData.push({
                    "ID": 0,
                    "CustomerId": "",
                    "StoreName": "",
                    "StoreUrl": "",
                    "WebsiteType": "",
                    "LastSynResult": "",
                    "CreatedAt": "",
                    "UpdatedAt": "",
                    "WebsiteTypeText": "-"
                });
            }
        },
        async getData() {
            var that = this;
            that.loading = true;
            that.tableData = [];
            let { code, msg, data } = await submit("/api/product/spider/getSynPlatforms");
            if (code == 200) {
                that.tableData = data ?? [];
            } else {
                this.$baseMessage(msg, "error");
            }
            this.addEmptyRow();
            that.loading = false;
        },
        async handleAdd(row, rowIndex) {
            if (row.StoreName == '') {
                this.$message({ type: 'error', message: "店铺名称不能为空" });
                return;
            }
            if (row.StoreUrl == '') {
                this.$message({ type: 'error', message: "店铺网址不能为空" });
                return;
            }
            let { code, msg } = await submit("/api/product/spider/addSynPlatform", row);
            if (code == 200) {
                this.$message({ type: 'success', message: msg });
                await this.getData();
            } else {
                this.$message({ type: 'error', message: msg });
            }
        },
        async handleDelete(row, rowIndex) {
            if (this.tableData.length > 1) {
                var lastRow = this.tableData[rowIndex];
                if (lastRow.ID <= 0) {
                    return;
                }
                let { code, msg } = await submit("/api/product/spider/deleteSynPlatform", { ID: row.ID });
                if (code == 200) {
                    this.$message({ type: 'success', message: msg });
                    this.tableData.splice(rowIndex, 1);
                }
            }
        },
        handleCancel() {
            this.tableData = [];
            this.visible = false;
        },
        async handleImportByStoreUrls(row) {
            let hour = new Date().getHours();
            if (hour >= 14 && hour < 20) {
                this.$baseMessage("为避免影响接单功能，14点-20点不可执行爬取商品操作", "error");
                return;
            }

            await submit("/api/product/spider/ImportByStoreUrls", {
                Ids: row.ID.toString(),
            });
            this.$message({ type: "success", message: "开始导入，请稍后刷新页面查看" });
            this.getData();
        },
    }
}
</script>
<style lang="scss" scoped>
.tooltip-item {
    margin: 4px;
}

::v-deep {

    .el-table__header th {
        background-color: #f3f7f9 !important;
        border-color: #ebeef4 !important;
    }
}
</style>
