<template>
  <el-dialog v-dialog-drag :title="title" :width="width" :visible="visible" :close-on-click-modal="false"
    @close="handleClose">
    <el-tabs v-model="activeTab">
      <el-tab-pane label="商品网址导入" name="itemUrl">
        <el-form ref="itemUrlForm" :model="itemUrlTabData" :rules="itemUrlTabData.rules" label-width="100px">
          <el-form-item label="网址列表：" prop="Urls">
            <el-input type="textarea" size="medium" v-model="itemUrlTabData.Urls" placeholder="请输入采集的商品网址，可换行输入" />
          </el-form-item>
          <el-form-item>
            <div style="margin-top: 10px; text-align: right">
                <span style="margin-right: 10px; color: rgb(255, 0, 0)">多商品，可换行输入</span>
              <el-button type="primary" size="small" icon="el-icon-plus" :loading="itemUrlTabData.loading"
                @click="handleImportByItemUrls">导入</el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="表格导入" name="tabXls">
        <div class="xls-import">
          <el-upload drag ref="uploadXls" accept=".xls,.xlxs" :auto-upload="false" :limit="1" :show-file-list="true"
            :action="xlsTabData.uploadUrl" :headers="xlsTabData.headers" :on-success="uploadXlsSuccess"
            :on-error="uploadXlsError">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">只能上传excel文件。</div>
          </el-upload>
          <div class="btn-wrap">
            <el-button type="success" size="small" :loading="xlsTabData.loading1" @click="handleDownXls">下载模板</el-button>
            <el-button type="primary" size="small" :loading="xlsTabData.loading2"
              @click="handleImportByXls">导入数据</el-button>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>
<script>
import { submit } from "@/api/base";
import { baseURL, successCode } from "@/config";
export default {
  name: "productImport",
  data() {
    return {
      visible: false,
      storeVisible: false,
      width: "600px",
      title: "导入货品",
      activeTab: "storeUrl",
      itemUrlTabData: {
        Urls: "",
        loading: false,
        rules: {
          Urls: [
            { required: true, message: "请输入商品网址", trigger: "blur" },
          ],
        },
      },
      xlsTabData: {
        loading1: false,
        loading2: false,
        uploadUrl: `${baseURL}/api/product/importByXls`,
        headers: {
          Authorization: "Bearer " + this.$store.getters["user/accessToken"],
        },
      },
    };
  },
  methods: {
    async initDialog() {
      this.itemUrlTabData.Urls = '';
      if (!this.storeVisible) {
        this.activeTab = "itemUrl";
      }
      else {
        this.activeTab = "storeUrl";
      }
      this.visible = true;
    },
    handleClose() {
      this.visible = false;
    },
    async handleImportByItemUrls() {
      this.$refs.itemUrlForm.validate(async (valid) => {
        if (!valid) return false;

        this.itemUrlTabData.loading = true;
        const result = await submit("/api/product/spider/ImportByItemUrls", {
          Urls: this.itemUrlTabData.Urls,
        }).finally(() => {
          this.itemUrlTabData.loading = false;
        });

        if (result.success) {
          this.$message({
            type: "success",
            message: "开始导入，请稍后刷新页面查看",
          });
          this.$emit("handleSearch");
        } else {
          this.$message({
            type: "error",
            message: result.msg,
          });
        }
      });
    },
    async handleDownXls() {
      var a = document.createElement("a");
      a.href = "/static/Upload/商品导入模板-新.xls";
      a.download = "商品导入模板-新.xls";
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    handleImportByXls() {
      if (this.$refs.uploadXls.uploadFiles.length<=0) {
        this.$message({
          type: "error",
          message: "请选择要上传的文件",
        });
        return;
      }
      this.xlsTabData.loading2 = true;
      this.$refs.uploadXls.submit();
    },
    uploadXlsSuccess(res) {
      if (successCode.includes(res.code)) {
        this.handleClose();
        this.$alert(res.msg, "导入结果", {
          dangerouslyUseHTMLString: true,
        });
        this.$emit("handleSearch");
      } else {
        this.$message({
          type: "error",
          message: res.msg,
        });
      }
      this.$refs.uploadXls.clearFiles();
      this.xlsTabData.loading2 = false;
    },
    uploadXlsError() {
      this.$message({
        type: "error",
        message: "导入失败",
      });
      this.xlsTabData.loading2 = false;
    },
  },
};
</script>
<style scoped>
.xls-import {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.xls-import .btn-wrap {
  margin-top: 10px;
}
</style>
